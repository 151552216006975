import React, { useEffect, useState } from 'react'
import {useHistory } from "react-router-dom";
import css from "../css/Refer.module.css"
import axios from 'axios'
import Swal from 'sweetalert2'

const Transfer = () => {
  const history = useHistory();
  const [user, setUser] = useState()
  const [mobileNumber, setMobileNumber] = useState('')
  const [amount, setAmount] = useState('')
  const [isClicked, setIsClicked] = useState(false);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const Cashheader = () => {
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(`https://apichmodrty.bazigarludo.com/me`, { headers })
      .then((res) => {
        setUser(res.data)
        Allgames(res.data.referral_code)
      }).catch((e) => {
        alert(e.msg)
      })
  }

  const [cardData, setGame] = useState([])

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(`https://apichmodrty.bazigarludo.com/referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data)
        console.log(res.data)
      })
  }

  useEffect(() => {
    Cashheader()
    //eslint-disable-next-line
  }, [])

  
  const handleTransfer = async () => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const data = {
      friendMobile: mobileNumber,
      amount: parseFloat(amount)
    };
  
    if (isClicked) return;
    setIsClicked(true);
  
    try {
      const response = await axios.post(`${baseUrl}user/transferwithfriend`, data, { headers });
      if (response.status === 200 && response.data.message) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Amount Transferred Successfully',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Transfer Failed',
          text: response.data.message || 'Something went wrong',
          showConfirmButton: true
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Transfer Failed',
        text: errorMessage,
        showConfirmButton: true
      });
    } finally {
      setIsClicked(false);
    }
  };
  

  if (user === undefined) {
    return null
  }

  return (
    <div>
      <div className='leftContainer'>
        <div className={`${css.center_xy} pt-5`}>
          <picture className="mt-1">
            <img alt="img" width="226px" src={process.env.PUBLIC_URL + 'Images/refer/refer.png'} className="snip-img" />
          </picture>
          <div className="mb-1 w-100">
            <div className='container'>
              <label className='form-label'>Mobile Number</label>
              <input
                className="form-control mb-2"
                type='number'
                placeholder='Friend Mobile Number'
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <label className='form-label'>Amount</label>
              <input
                className="form-control"
                type='number'
                placeholder='Amount'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <button
                className="bg-green w-100 py-2 mt-3 rounded text-light border border-white"
                onClick={handleTransfer}
                disabled={isClicked}
              >
                SEND
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transfer
